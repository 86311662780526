.css-6kt3cr-MuiPaper-root-MuiCard-root {
  min-height: calc(100vh - 105px) !important;
}
.css-172osot {
  background-color: #ffffff;
  padding: 0 24px;
  border-radius: 8px;
}
.css-zw9y3a-MuiFormControl-root-MuiTextField-root .MuiInputBase-root {
  font-size: 14px;
  border-radius: 10px;
  width: 100%;
  height: 42px;
  border: 1px solid #f44336;
}
.word-wrap-cell {
  white-space: normal !important; /* Allows text to wrap */
  word-break: break-word; /* Breaks long words */
  overflow-wrap: break-word; /* Ensures text doesn't overflow */
  max-width: 100%; /* Ensures it stays within column width */
  line-height: 1.4; /* Improves readability */
}