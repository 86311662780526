@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

@font-face {
  font-family: "MyCustomFont";
  src: url("../public/Font/gadugi-normal.ttf") format("truetype");
  /* url('../public/Font/gadugi-gras.ttf') format('truetype'),
    url('../public/Font/gadugi-normal.ttf') format('truetype'); */
  font-weight: normal;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: inherit;
}

main {
  overflow-x: hidden;
  padding: 28px !important;
  background-color: transparent !important;
}

.color-test {
  color: #20cb67;
  color: #b3f4ce;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: #e9fbf4;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #5cabfa;
  border-radius: 10px;
  position: inherit;
}

::-webkit-scrollbar-track {
  background: transparent;
}

.login-img {
  height: 100dvh;
}

.login-screen {
  max-width: 600px;
  width: 100%;
  padding: 24px 36px;
}

@media (max-width: 1499px) {
  .login-img {
    width: 65vw;
  }
}

@media (max-width: 1199px) {
  .login-img {
    width: 60vw;
    object-fit: cover;
  }
}

@media (max-width: 991px) {
  .ball {
    --size: 16px;
    --timing: 0.6s;
    --displace: 240px;
  }

  .login-img {
    width: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
    height: 100%;
  }

  .login-screen {
    background: #ebeaec;
    border-radius: 24px;
    padding: 24px;
  }
}